import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Personalizations, TelevendaChannel, UserState } from './types'
import { parseCookies } from 'nookies'
import { CheckoutProfile } from '../checkout/types'

const initialState: UserState = {
    personalizations: { nome: '', nomeCompleto: '', nomeTelevendedor: '' },
    userId: '',
    deviceId: '',
    acceptedCookies: false,
    televendasChannelData: []
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserData(state) {
            return {
                ...initialState,
                deviceId: state.deviceId,
                acceptedCookies: state.acceptedCookies,
                televendasChannelData: state.televendasChannelData
            }
        },
        _onUpdatePersonalizationsSuccess(
            state,
            action: PayloadAction<Personalizations>
        ) {
            state.personalizations = {
                nome: action.payload.nome,
                nomeCompleto: action.payload.nomeCompleto,
                nomeTelevendedor: action.payload.nomeTelevendedor
            }
        },
        _setUserId(state, action: PayloadAction<number>) {
            state.userId = action.payload.toString()
        },
        _onUpdateCheckoutProfile(
            state,
            action: PayloadAction<CheckoutProfile>
        ) {
            state.userId = action.payload.idUsuario
        },
        createDeviceIdIfDoesntExists(state) {
            let chaordicBrowserId = ''

            try {
                chaordicBrowserId = parseCookies().chaordic_browserId
            } catch (error: any) {
                console.error('Não foi possível obter chaordic_browserId')
            }

            if (
                chaordicBrowserId != undefined &&
                (state.deviceId === '' || state.deviceId !== chaordicBrowserId)
            ) {
                state.deviceId = chaordicBrowserId
            }
        },
        setAcceptedCookies(state) {
            state.acceptedCookies = true
        },
        getTelevendasChannelDataSuccess(
            state,
            action: PayloadAction<TelevendaChannel[]>
        ) {
            state.televendasChannelData = action.payload
        }
    }
})

export const userActions = userSlice.actions

export default userSlice
